import { Modal, Platform } from 'react-native';

import { MaterialIcons } from '@expo/vector-icons';
import styled, { css } from 'styled-components/native';

import theme from '../../theme/index';

export const Container = styled.View`
  ${Platform.select({
  web: css``,
  android: css`
      flex: 1;
    `,
  ios: css`
      flex: 1;
    `,
})}
`;

export const ModalContainer = styled(Modal).attrs(() => ({
  animationType: 'slide',
  transparent: true,
}))`
  ${Platform.select({
  web: css``,
  android: css``,
  ios: css``,
})}
`;

export const ModalBackdrop = styled.View`
  ${Platform.select({
  web: css`
      margin-top: 30vh;
      justify-content: center;
      align-items: center;
      height: 160px;
      width: 100%;
    `,
  android: css`
      flex: 1;
      background-color: rgba(0, 0, 0, 0.7);
    `,
  ios: css`
      flex: 1;
      background-color: rgba(0, 0, 0, 0.7);
    `,
})}
`;

export const Conteudo = styled.View`
  ${Platform.select({
  web: css`
      align-items: center;
      justify-content: center;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: ${theme.cores.sucesso};
      padding: 16px;
      height: 100%;
      margin-top: auto;
      width: 90%;

      position: relative;
    `,
  android: css`
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-color: ${theme.cores.sucesso};
      padding: 16px;
      height: 90%;
      margin-top: auto;

      position: relative;
    `,
  ios: css`
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-color: ${theme.cores.sucesso};
      padding: 16px;
      height: 90%;
      margin-top: auto;

      position: relative;
    `,
})}
`;

export const Titulo = styled.Text`
  font-size: ${theme.fontes.grande};
  font-weight: bold;
  color: ${theme.cores.primariaClara};
  text-align: center;
  margin-bottom: 16px;
`;

export const BotaoFechar = styled.TouchableOpacity`
  height: 30px;
  width: 40px;
  border-radius: 5px;
  background-color: ${theme.cores.primariaClara};
  align-items: center;
  justify-content: center;
  align-self: flex-end;

  top: 0px;
  right: 10px;
  z-index: 3;
`;

export const Text = styled.Text`
  font-size: ${theme.fontes.grande};
  font-weight: bold;
  color: ${theme.cores.unitech};
  text-align: center;
  margin-bottom: 10px;
`;

export const BotaoFecharIcone = styled(MaterialIcons).attrs(() => ({
  name: 'close',
  size: 25,
  color: theme.cores.cinzas.c3,
}))``;
